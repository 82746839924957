import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import {OccurrenceService} from "../../services/OccurrenceService";
import dateFormat from "dateformat";
import {DATETIME_FORMAT} from "../../Constants";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {Calendar} from "primereact";

export const OccurrenceDataTable = forwardRef((props, ref) => {

    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [occurrenceList, setOccurrenceList] = useState([]);
    const isMounted = useRef(false);
    const layout = 'list';

    useEffect(() => {
        setTimeout(() => {
            isMounted.current = true;
            setLoading(true);
            loadList();
        }, 1000);
    }, []);

    useEffect(() => {
        if (isMounted.current) {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }, [loading]);

    useImperativeHandle(ref, () => ({
            reloadList(){
                current:loadList()
            }
        })
    );
    const fixDates = (data) => {
        return [...(data || [])].map((d) => {
            d.createdOn = new Date(d.createdOn);
            return d;
        });
    };

    const loadList = () => {
        const occurrenceService = new OccurrenceService();
        occurrenceService.getList()
            .then(response => {
                setOccurrenceList(
                    fixDates(response.data)
                );
                setLoading(false);
            });
    }

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value}
                         onChange={(e) => options.filterCallback(e.value, options.index)}
                         dateFormat="dd.mm.yy" placeholder="dd.mm.yy" mask="99.99.9999" />;
    }

    const createdOnBody = (rowData) => {
        return dateFormat(rowData.createdOn, DATETIME_FORMAT);
    }
    const uinBody = (rowData) => {
        return (
            <React.Fragment>
                <Link to={ "/company/occurrence/" + rowData.uuid}>
                    <div className="product-list-detail">
                        <div className="product-name">{rowData.uin}</div>
                    </div>
                </Link>
            </React.Fragment>
        );
    }

    const numberBody = (rowData) => {
        return (
            <React.Fragment>
                <Link to={ "/company/occurrence/" + rowData.uuid}>
                    <div className="product-list-detail">
                        <div className="product-name">{rowData.number}</div>
                    </div>
                </Link>
            </React.Fragment>
        );
    }
    const statusBody = (rowData) => {
        return (
            <React.Fragment>
                <div className="text-yellow-700">{t(rowData.status).toUpperCase()}</div>
            </React.Fragment>
        );
    }
    const subjectBody = (rowData) => {
        return (
            <React.Fragment>
                <Link to={ "/company/occurrence/" + rowData.uuid}>
                <div className="product-list-detail">
                    <div className="product-name">{rowData.subject}</div>
                </div>
                </Link>
            </React.Fragment>
        );
    }

    const categoryNameBody = (rowData) => {
        return (
            <React.Fragment>
                <div>{rowData.categoryName}</div>
            </React.Fragment>
        );
    }

    return (
        <DataTable value={occurrenceList} layout={layout} paginator rows={props.rows} loading={loading}>
            <Column field="uin" body={uinBody} header={t('uin')}/>
            <Column field="number" body={numberBody} header={t('number')}/>
            <Column field="subject" body={subjectBody} header={t('subject')}/>
            <Column field="createdOn" body={createdOnBody} style={{ textAlign: "right" }} header={t('created_on')}
                    filter dataType="date" filterElement={dateFilterTemplate} filterField="createdOn"/>
            <Column field="createdOn" body={createdOnBody} style={{ textAlign: "right" }} header={t('dead_line')}/>
            <Column field="categoryName" body={categoryNameBody} style={{ textAlign: "right" }} header={t('category')}/>
            <Column field="status" style={{ textAlign: "right" }} body={statusBody} header={t('status')} sortable filter={true} filterField="status"/>
        </DataTable>
    );
})