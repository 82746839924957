import {AppFooter} from "../AppFooter";
import {layoutColorMode} from "../constants/Constants";
import React, {useEffect, useState} from "react";
import {WhistleBlowingHeader} from "./WhistleBlowingHeader";
import {MessageService} from "../services/MessageService";
import "../assets/css/Message.css";
import {LoadingSpinner} from "../components/LoadingSpinner";
import {OccurrenceDetTable} from "../components/partial/OccurrenceDetTable";
import {OccurrenceMessageComponent} from "../components/partial/OccurrenceMessageComponent";
import {FROM_USER} from "../constants/MessageType";

export const WhistleBlowingCheck = (props) => {

    const [isLoading, setIsLoading] = useState(false);
    const [messageList, setMessageList] = useState();
    if (!props.location.state){
        props.history.push("./");
    }
    const [occurrence, setOccurrence] = useState(props.location.state);

    useEffect(() => {
        setTimeout(async () => {
            setOccurrence(props.location.state);
            loadMessageList(props.location.state.occurrenceId);
            setIsLoading(false)
        }, 1000);
    }, []);

    const loadMessageList = (occurrenceId) => {
        const messageService = new MessageService();
        messageService.getByOccurrenceId(occurrenceId)
            .then(response => {
                setMessageList(response.data);
            });
    }

    return (
        <div>
            <LoadingSpinner isLoading={isLoading}/>
            <div className="wb-container">
                <WhistleBlowingHeader/>

                <OccurrenceDetTable number={occurrence.number + " / " + occurrence.occurrenceId}
                                    subject={occurrence.subject}
                                    description={occurrence.description}
                                    createdOn={occurrence.createdOn}
                                    lastUpdateOn={occurrence.lastUpdateOn}
                                    source={occurrence.source}
                                    status={occurrence.status}
                                    uin={occurrence.uin}
                                    categoryName={occurrence.categoryName}
                                    fileList={occurrence.fileList}
                                    showContact={false}/>

                <OccurrenceMessageComponent messageList={messageList} loadMessageList={loadMessageList}
                                            occurrenceId={occurrence.occurrenceId} messageType={FROM_USER}
                                            status={occurrence.status}/>
            </div>

            <AppFooter layoutColorMode={layoutColorMode}/>
        </div>
    );
}
export default WhistleBlowingCheck;