import React, {useEffect, useRef, useState} from "react";
import {useParams} from "react-router";
import {OccurrenceService} from "../../services/OccurrenceService";
import Occurrence from "../../models/Occurrence";
import {LoadingSpinner} from "../LoadingSpinner";
import {OccurrenceDetTable} from "./OccurrenceDetTable";
import {OccurrenceMessageComponent} from "./OccurrenceMessageComponent";
import {MessageService} from "../../services/MessageService";
import {ActivityService} from "../../services/ActivityService";
import {Timeline} from "primereact/timeline";
import dateFormat from "dateformat";
import {DATETIME_FORMAT} from "../../Constants";
import {useTranslation} from "react-i18next";
import authService from "../../services/auth.service";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {CLOSE, INTERNAL} from "../../constants/MessageType";
import {DEBUG} from "../../constants/Constants";

export const OccurrenceDetailComponent = () => {

    const {t} = useTranslation();
    const { uuid } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [showActivityDialog, setShowActivityDialog] = useState(false);

    const [occurrence, setOccurrence] = useState(new Occurrence());
    const [messageList, setMessageList] = useState();
    const [occurrenceId, setOccurrenceId] = useState(null);

    const [activityList, setActivityList] = useState();
    const [showCloseDialog, setShowCloseDialog] = useState(false);
    const messageComponentReference = useRef();

    useEffect(() => {
        setTimeout(async () => {
            await loadOccurrence();
        }, 1000);
    }, []);

    const loadOccurrence = async () => {
        const occurrenceService = new OccurrenceService();
        await occurrenceService.getByUUID(uuid)
            .then(response => {
                if (response.status === 200) {
                    setOccurrence(response.data);
                    setOccurrenceId(response.data.occurrenceId);
                    loadMessageList(response.data.occurrenceId);
                    loadActivityList(response.data.occurrenceId);
                    setIsLoading(false);
                }
            });
    }

    const loadMessageList = (occurrenceId) => {
        const messageService = new MessageService();
        messageService.getByOccurrenceId(occurrenceId)
            .then(response => {
                setMessageList(response.data);
            });
    }

    const loadActivityList = (occurrenceId) => {
        const activityService = new ActivityService();
        activityService.getByOccurrenceId(occurrenceId)
            .then(response => {
                if (DEBUG){
                    console.log("/***** Activity list *****/");
                    console.log(response.data);
                }
                setActivityList(response.data);
            });
    }

    const doShowActivityDialog = (show) => {
        setShowActivityDialog(show);
    }
    const doShowCloseDialog = (show) => {
        setShowCloseDialog(show);
    }

    const closeDialogContent = () => {
        return (
            <OccurrenceMessageComponent async occurrenceId={occurrenceId} status={occurrence.status} ref={messageComponentReference}
                                        userId={authService.getCurrentUser().user_name} messageType={CLOSE} />
        // onSave={onSave}
        );
    }
    const closeOccurrence = () => {
        messageComponentReference.current.doSendMessage();
        hideCloseDialog();
    }
    const footerCloseTemplate = () => {
        return(
            <div>
                <Button label={t("cancel")} className="p-button-text" icon="pi pi-times" onClick={hideCloseDialog}/>
                <Button label={t("save")} icon="pi pi-check" onClick={closeOccurrence}/>
            </div>
        );
    }

    const hideCloseDialog = () =>{
        setShowCloseDialog(false);
    }
    const activityItem = (item) => {
        let itemRow = t(item.description);
        if (item.userName !== undefined){
            itemRow = itemRow + item.userName;
        }
        return( // icon + " " +
            <div dangerouslySetInnerHTML={{__html: itemRow}}>

            </div>
        )
    }
    const closeButtonComponent = () => {
        if (occurrence.status !== 'CLOSED') {
            return(
                <Button label={t("close_occurrence")} icon="pi pi-external-link"
                        onClick={() => doShowCloseDialog(true)}  className="p-button-warning wid40 ml-2"/>
            )
        }
    }

    const onUINChange = () => {
        // occurrence.uin = newUID;
        loadOccurrence();
    }

    return (
      <div>
          <LoadingSpinner isLoading={isLoading}/>
          <div className="p-fluid grid">
              <div className="col-12 md:col-6">
                  <OccurrenceDetTable number={occurrence.number + " / " + occurrence.occurrenceId}
                                      subject={occurrence.subject}
                                      description={occurrence.description}
                                      createdOn={occurrence.createdOn}
                                      lastUpdateOn={occurrence.lastUpdateOn}
                                      source={occurrence.source}
                                      status={occurrence.status}
                                      categoryName={occurrence.categoryName}
                                      fileList={occurrence.fileList}
                                      uuid={uuid}
                                      uin={occurrence.uin}
                                      showContact={true}
                                      occurrenceId={occurrence.occurrenceId}
                                      onUINChange={onUINChange}  />
                  <div className="card col-12 md:col-12">
                      <Button label={t("activity")} icon="pi pi-external-link"
                              onClick={() => doShowActivityDialog(true)} className="p-button-info wid40" />
                      {closeButtonComponent()}
                  </div>
              </div>
              <div className="col-12 md:col-6">
                   <OccurrenceMessageComponent async messageList={messageList} loadMessageList={loadMessageList}
                                               status={occurrence.status} messageType={INTERNAL}
                                               occurrenceId={occurrenceId} userId={authService.getCurrentUser().user_name} />
              </div>
              <Dialog header={t("activity")} visible={showActivityDialog} style={{width: "50%"}}
                      onHide={() => doShowActivityDialog(false)}>
                  <div className="card">
                        <Timeline value={activityList}
                                  opposite={(item) => activityItem(item)}
                            content={(item) => <small className="p-text-secondary">{dateFormat(item.createdOn, DATETIME_FORMAT)}</small>} />
                  </div>
              </Dialog>
              <Dialog visible={showCloseDialog} onHide={hideCloseDialog} className="wid45" footer={footerCloseTemplate}
                      header={<h4>{ t("close_occurrence")}</h4>} focusOnShow={true}>
                  {closeDialogContent()}
              </Dialog>
          </div>
      </div>
    );
}