import { BASE_DOMAIN } from '../constants/Constants';
import authHeader from "./AuthHeader";
import api from "./api";

var service_path = 'occurrence';

export class OccurrenceService {
    getList(){
        return api.get( BASE_DOMAIN + "api/"+service_path+"/list", {
            headers:  authHeader()
        });
    }

    getDeanonymization(uuid){
        return api.get( BASE_DOMAIN + "api/"+service_path+"/deanonymization/"+uuid, {
            headers:  authHeader()
        });
    }
    getPerpetrator(uuid){
        return api.get( BASE_DOMAIN + "api/"+service_path+"/perpetrator/"+uuid, {
            headers:  authHeader()
        });
    }

    respondToRequest(respondTo, uuid, respondStatus){
        return api.get( BASE_DOMAIN + "api/"+service_path+"/response-"+respondTo+"/"+uuid+"/"+respondStatus, {
            headers:  authHeader()
        });

    }

    async getByUUID(uuid){
        return await api.get( BASE_DOMAIN + "api/"+service_path+"/get/"+uuid, {
            headers:  authHeader()
        });
    }

    getCountByDate(dateFrom, dateTo){
        return api.get( BASE_DOMAIN + "api/"+service_path+"/list/count-date-between/"+dateFrom+"/"+dateTo , {
            headers:  authHeader()
        });
    }

    getCountByStatus(dateFrom, dateTo){
        return api.get( BASE_DOMAIN + "api/"+service_path+"/list/count-status-between/"+dateFrom+"/"+dateTo, {
            headers:  authHeader()
        });
    }
    getStatusCount(){
        return api.get( BASE_DOMAIN + "api/"+service_path+"/list/count-status", {
            headers:  authHeader()
        });
    }

    async create(occurrence, token) {
        return api.post(BASE_DOMAIN + "api/" + service_path + "/create/" + token, occurrence,
            {headers: authHeader()});
    }

    async saveUIN(occurrenceUUID, uuid, uin) {
        return api.put(BASE_DOMAIN + "api/" + service_path + "/update-uin/" + occurrenceUUID,
            {"user" : uuid, "uin": uin},
            {headers: authHeader()});
    }
}