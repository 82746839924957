import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import {InputSwitch} from "primereact/inputswitch";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {CompanyService} from "../../services/CompanyService";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {classNames} from "primereact/utils";

export const CompanyCreateDialog = (props) =>{

    const { t } = useTranslation();
    const [createCompanyDialog, setCreateCompanyDialog] = useState(false);
    const [newCompany, setNewCompany] = useState([]);
    const [newCompanyStatus, setNewCompanyStatus] = useState(false);
    const [userDialog, setUserDialog] = useState(false);
    let emptyUser = {
        firstName: '',
        lastName: '',
        position: '',
        phone: '',
        email: ''
    }
    const [submitted, setSubmitted] = useState(false);
    const [user, setUser] = useState(emptyUser);

    const showNewCompanyDialog = () => {
        setNewCompany("");
        setCreateCompanyDialog(true);
    };

    const hideNewCompanyDialog = () => {
        setCreateCompanyDialog(false);
    };

    const handleNewCompanyChange = (e) => {
        setNewCompany(e.target.value);
    }

    const createNewCompany = async () => {
        const companyService = new CompanyService();
        await companyService.create(newCompany)
            .then( response => {
            props.reLoadList();
            hideNewCompanyDialog();
        });
    }

    const renderNewFooter = (name) => {
        return (
            <div>
                <Button label={t('cancel')} icon="pi pi-times" onClick={() => hideNewCompanyDialog(name)} className="p-button-text" />
                <Button label={t('save')} icon="pi pi-check" onClick={() => createNewCompany()} style={{margin: "0"}}
                        disabled={newCompany === ''} />
            </div>
        );
    }

    const handleKeyPress = (e) => {
        if (e.key === "Enter" && newCompany !== ''){
            createNewCompany();
        }
    }

    const onStatusChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _company = {...newCompany};
        _company[`${name}`] = val ? 'ACTIVE':'INACTIVE';
        setNewCompany(_company);
        setNewCompanyStatus(val);
    }
    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _company = {...newCompany};
        _company[`${name}`] = val;

        setNewCompany(_company);
    }
    const onUserInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _user = {...user};
        _user[`${name}`] = val;
        setUser(_user);
    }
    const userKeyPress = (e, name) => {
        if (e.key === "Enter" && user && user.name !== '' && name === 'name'){
            document.getElementById('position').focus();
            return;
        }
        if (e.key === "Enter" && user && user.name !== ''){
            addUser();
        }
    }
    
    const textEditor = (options) => {
        return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    }
    const onRowEditComplete = (e) => {
        let _userList = [...newCompany.userList];
        let { newData, index } = e;

        _userList[index] = newData;

        newCompany.userList = _userList;
    }
    const openUserDialog = () => {
        setUser(emptyUser);
        setSubmitted(false);
        setUserDialog(true);
    }

    const hideUserDialog = () => {
        setUserDialog(false);
    }

    const confirmDeletePerpetrator = (user) => {
         setUser(user);
        // setDeletePerpetratorDialog(true);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-trash" rounded outlined severity="danger" className="p-button-outlined p-button-danger"
                        onClick={() => confirmDeletePerpetrator(rowData)} />
            </React.Fragment>
        );
    };
    const addUser = () => {
        setSubmitted(true);
        if (user.firstName.trim()) {
            let _newCompany = {...newCompany};
            if (!_newCompany.userList){
                _newCompany.userList = [];
            }
            _newCompany.userList.push(user);
            setNewCompany(_newCompany);
            hideUserDialog();
            setSubmitted(false);
        }
    }
    const userDialogFooter = (
        <React.Fragment>
            <Button label={t("cancel")} icon="pi pi-times" className="p-button-text" onClick={hideUserDialog} />
            <Button label={t("add")} icon="pi pi-check" className="p-button p-button-success" onClick={addUser} />
        </React.Fragment>
    );

    return (
        <div className="p-dataview-header">
            <h1>{t("companies")}</h1>
            <Button icon="pi pi-plus" label={t("create") + " " + t("company")} onClick={() => showNewCompanyDialog()}/>
            <Dialog header={t("create") + " " + t("company")} visible={createCompanyDialog}
                    onHide={() => hideNewCompanyDialog('displayResponsive')}
                    breakpoints={{'560px': '75vw'}} style={{width: '40vw', minWidth: "400px"}}
                    footer={renderNewFooter()}>
                <div style={{paddingTop: '15px', paddingBottom: "7.5px"}}>
                        <span className="p-float-label">
                            <InputText id="company_name" className="wid100" value={newCompany.name}
                                       onChange={(e) => onInputChange(e, 'name')} onKeyPress={handleKeyPress} autoFocus/>
                            <label htmlFor="company_name">{t('name')}</label>
                        </span>
                </div>
                <div style={{paddingTop: '15px', paddingBottom: "7.5px"}}>
                        <span className="p-float-label">
                            <InputText id="vatNo" className="wid100" value={newCompany.vatNumber}
                                       onChange={(e) => onInputChange(e, 'vatNumber')}/>
                            <label htmlFor="vatNo">{t("vat_no")}</label>
                        </span>
                </div>
                <div style={{paddingTop: '7.5px'}}>
                    <h5>{t('status')}
                        <InputSwitch checked={newCompanyStatus} style={{float: "right"}}
                                     onChange={(e) => onStatusChange(e, 'status')}>

                        </InputSwitch>
                    </h5>
                </div>
                <div className="card p-fluid">
                    <label dangerouslySetInnerHTML={{__html: t('users')}}/>
                    <Button label={t("add")} icon="pi pi-plus" className="p-button-success floatRight wid25"
                            style={{marginTop: "-20px", marginBottom: "10px"}} onClick={openUserDialog}/>
                    <DataTable value={newCompany.userList} editMode="row" dataKey="id" className="clearBoth wid100"
                               onRowEditComplete={onRowEditComplete} responsiveLayout="scroll"
                               emptyMessage={t("no_perpetrators")}>
                        <Column field="firstName" header={t("first_name")} editor={(options) => textEditor(options)}
                                style={{width: '25%'}}></Column>
                        <Column field="lastName" header={t("last_name")} editor={(options) => textEditor(options)}
                                style={{width: '30%'}}></Column>
                        <Column field="position" header={t("position")} editor={(options) => textEditor(options)}
                                style={{width: '30%'}}></Column>
                        <Column field="phone" header={t("phone")} editor={(options) => textEditor(options)}
                                style={{width: '30%'}}></Column>
                        <Column field="email" header={t("email")} editor={(options) => textEditor(options)}
                                style={{width: '30%'}}></Column>
                        <Column body={actionBodyTemplate} exportable={false} style={{width: '10%'}}/>
                    </DataTable>
                </div>
            </Dialog>
            <Dialog visible={userDialog} style={{width: '450px'}} header={t("new") + " " + t("user")} modal
                    className="p-fluid"
                    footer={userDialogFooter} onHide={hideUserDialog}>
                <div className="field">
                    <label htmlFor="first_name">{t("first_name")}</label>
                    <InputText id="first_name" value={user.firstName} required autoFocus
                               onKeyPress={(event) => userKeyPress(event, 'firstName')}
                               onChange={(e) => onUserInputChange(e, 'firstName')}
                               className={classNames({'p-invalid': submitted && !user.firstName})}/>
                    {submitted && !user.firstName && <small className="p-error">Name is required.</small>}
                </div>
                <div className="field">
                    <label htmlFor="last_name">{t("last_name")}</label>
                    <InputText id="last_name" value={user.lastName} required autoFocus
                               onKeyPress={(event) => userKeyPress(event, 'lastName')}
                               onChange={(e) => onUserInputChange(e, 'lastName')}
                               className={classNames({'p-invalid': submitted && !user.lastName})}/>
                    {submitted && !user.lastName && <small className="p-error">Name is required.</small>}
                </div>
                <div className="field">
                    <label htmlFor="position">{t("position")}</label>
                    <InputText id="position" value={user.position} required
                               onKeyPress={(event) => userKeyPress(event, 'position')}
                               onChange={(e) => onUserInputChange(e, 'position')}/>
                </div>
                <div className="field">
                    <label htmlFor="phone">{t("phone")}</label>
                    <InputText id="phone" value={user.phone} required
                               onKeyPress={(event) => userKeyPress(event, 'phone')}
                               onChange={(e) => onUserInputChange(e, 'phone')}/>
                </div>
                <div className="field">
                    <label htmlFor="email">{t("email")}</label>
                    <InputText id="email" value={user.email} required
                               onKeyPress={(event) => userKeyPress(event, 'email')}
                               onChange={(e) => onUserInputChange(e, 'email')}/>
                </div>
            </Dialog>
        </div>
    )
}