import React, {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {DATETIME_FORMAT} from "../../Constants";
import dateFormat from "dateformat";
import {FileListTable} from "./FileListTable";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {OccurrenceMessageComponent} from "./OccurrenceMessageComponent";
import authService from "../../services/auth.service";
import {CONFIRMED, DEANONYMISE, PERPETRATOR, REJECTED} from "../../constants/MessageType";
import {DEBUG} from "../../constants/Constants";
import {OccurrenceService} from "../../services/OccurrenceService";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {InputText} from "primereact/inputtext";

export const OccurrenceDetTable = (props) => {

    const {t} = useTranslation();
    const [showDialog, setShowDialog] = useState(false);
    const [showUINDialog, setShowUINDialog] = useState(false);
    const [showInfoDialog, setShowInfoDialog] = useState(false);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    const [messageType, setMessageType] = useState("");
    const [deanonymizeHeader, setDeanonymizeHeader] = useState("");
    const [infoHeader, setInfoHeader] = useState("");
    const [infoContent, setInfoContent] = useState([]);
    const [uin, setUin] = useState(props.uin);


    const messageComponentReference = useRef();
    const occurrenceService = () => {
        return new OccurrenceService();
    }

    const deanonymise = async () => {
        if (DEBUG) console.log("Clicked deanonymise Whistleblower button");
        // Call the server to check users permissions for deanonimysation

        await occurrenceService().getDeanonymization(props.uuid)
            .then(response => {
                // case status 200 and the user has permit to view - show the dialog
                if (response.status === 200) { // status : CONFIRMED, HTTP Status : Ok
                    previewInfoDialog("Процес по Деанонимизиране на подател - ДЕАНОНИМИЗИРАН",
                        wbTableContent([response.data], DEANONYMISE)
                    );
                } else if (response.status === 202) { // status : REQUESTED, HTTP Status : Accepted
                    previewInfoDialog("Процес по Деанонимизиране на подател - очаква одобрение",
                        "Вашата заявка за деанонимизиране на подател е подадена и очаква одобрение от страна на оператор.");
                } else if (response.status === 204) { // status : no request, HTTP Status : No Content
                    setDeanonymizeHeader(t("whistle_blower"));
                    setMessageType(DEANONYMISE);
                    setShowDialog(true);
                } else if (response.status === 206) { // status PARTIAL_CONTENT,
                    setMessageType(DEANONYMISE);
                    setShowConfirmationDialog(true);
                }
                }).catch(error => {
                if (error.response.status === 403) { // status : no request, HTTP Status : No Content
                    previewInfoDialog("Процес по Деанонимизиране на подател - ОТКАЗАН",
                        "Вашата заявка за деанонимизиране на подател е ОТКАЗАНА от страна на оператор.");
                }
                if (DEBUG) console.log(error);
            });
    }

    const hideDialog = () =>{
        setShowDialog(false);
    }

    const hideInfoDialog = () =>{
        setShowInfoDialog(false);
    }

    const previewInfoDialog = (headerText, contentText) => {
        setInfoHeader(headerText);
        setInfoContent(contentText);
        setShowInfoDialog(true);
    }

    const deanonymisePerpetrator = async () => {
        if (DEBUG) console.log("Clicked deanonymise Perpetrator button");
        // Call the server to check users permissions for deanonimysation

        await occurrenceService().getPerpetrator(props.uuid)
            .then(response => {
                // case status 200 and the user has permit to view - show the dialog
                if (response.status === 200) { // status : CONFIRMED, HTTP Status : Ok
                    previewInfoDialog("Процес по Деанонимизиране на извършител/и - ДЕАНОНИМИЗИРАН/И",
                        wbTableContent(response.data, PERPETRATOR)
                    );

                } else if (response.status === 202) { // status : REQUESTED, HTTP Status : Accepted
                    previewInfoDialog("Процес по Деанонимизиране на извършител/и - очаква одобрение",
                        "Вашата заявка за деанонимизиране на извършител/и е подадена и очаква одобрение от страна на оператор.");

                } else if (response.status === 204) { // status : no request, HTTP Status : No Content
                    setDeanonymizeHeader(t("perpetrator"));
                    setMessageType(PERPETRATOR);
                    setShowDialog(true);
                } else if (response.status === 206) { // status PARTIAL_CONTENT,
                    setMessageType(PERPETRATOR);
                    setShowConfirmationDialog(true);
                }
            }).catch(error => {
                if (error.response.status === 403) { // status : no request, HTTP Status : No Content
                    previewInfoDialog("Процес по Деанонимизиране на извършител/и - ОТКАЗАН",
                        "Вашата заявка за деанонимизиране на извършител/и е ОТКАЗАНА от страна на оператор.");
                }
                if (DEBUG) console.log(error);
            });
    }

    const wbTableContent = (data, type) => {
        if (type === DEANONYMISE){
            return(
                <div>
                    <DataTable value={data}>
                        <Column field="name" header={t("name")}></Column>
                        <Column field="email" header={t("email")}></Column>
                        <Column field="phone" header={t("phone")}></Column>
                        <Column field="district" header={t("district")}></Column>
                        <Column field="city" header={t("city")}></Column>
                        <Column field="address" header={t("address")}></Column>
                    </DataTable>
                </div>
            );
        } else {
            return(
                <div>
                    <DataTable value={data}>
                        <Column field="name" header={t("name")}></Column>
                        <Column field="position" header={t("position")}></Column>
                    </DataTable>
                </div>
            );
        }
    }

    const footerTemplate = () => {
        return(
            <div>
                <Button label={t("cancel")} className="p-button-text" icon="pi pi-times" onClick={hideDialog}/>
                <Button label={t("save")} icon="pi pi-check" onClick={onSave}/>
            </div>
        );
    }

    const footerInfoTemplate = () => {
        return(
            <div>
                <Button label={t("close")} icon="pi pi-times" onClick={hideInfoDialog}/>
            </div>
        );
    }

    const footerUIN = () => {
        return(
            <div>
                <Button label={t("cancel")} className="p-button-text" icon="pi pi-times" onClick={hideUINDialog}/>
                <Button label={t("save")} icon="pi pi-check" onClick={saveUIN}/>
            </div>
        );
    }

    const infoDialogContent = () => {
        return(
            <div>
                {infoContent}
            </div>
        );
    }

    const editUIN = () => {
        setUin(props.uin);
        setShowUINDialog(true);
    }

    const uinKeyPress = (e) => {
        if (e.key === "Enter" && uin && uin !== ''){
            saveUIN();
        }
    }

    const uinContent = () => {
        var editBtn = props.showContact ? (
            <Button
                id="wbPhone"
                icon="pi pi-pencil"
                style={{ display: "contents" }}
                onClick={editUIN}
                className="p-button-rounded p-button-success p-button-text floatLeft p-button-lg"
                tooltip={t("edit")}
                tooltipOptions={{ position: "bottom", mouseTrack: "true" }}
            />
        ) : (
            ""
        );
        return (
            <div className="col-12 md:col-12 clearBoth">
                <div className="col-12 md:col-4 floatLeft">
                    <label>{t("uin")}</label>
                </div>
                <div className="col-12 md:col-8 floatRight">
                    <strong>{props.uin}  </strong>
                    {editBtn}
                </div>
            </div>
        );
    }

    const contactContent = () => {
        if (props.showContact){
            return(
                <div className="col-12 md:col-12 clearBoth h-3rem">
                    <div className="col-12 md:col-4 floatLeft">
                        <label dangerouslySetInnerHTML={{__html: t('contact_info')}} />
                    </div>
                    <div className="col-12 md:col-8 floatRight">
                        <Button id="wbPhone" icon="pi pi-eye" style={{display: "contents"}} onClick={deanonymise}
                                className="p-button-rounded p-button-success p-button-text floatLeft p-button-lg"
                                tooltip={t('deanonymise')} tooltipOptions={{position:"bottom", mouseTrack: "true"}}/>
                    </div>
                </div>
            );
        } else {
            return (
                <div style={{display:"none"}}></div>
            )
        }

    }

    const perpetratorContent = () => {
        if (props.showContact){
            return(
                <div className="col-12 md:col-12 clearBoth h-3rem">
                    <div className="col-12 md:col-4 floatLeft">
                        <label dangerouslySetInnerHTML={{__html: t('perpetrator')}} />
                    </div>
                    <div className="col-12 md:col-8 floatRight">
                        <Button id="wbPhone" icon="pi pi-eye" style={{display: "contents"}} onClick={deanonymisePerpetrator}
                                className="p-button-rounded p-button-success p-button-text floatLeft p-button-lg"
                                tooltip={t('deanonymise_perpetrator')}
                                tooltipOptions={{position:"bottom", mouseTrack: "true"}}/>
                    </div>
                </div>
            );
        } else {
            return (
                <div style={{display:"none"}}></div>
            )
        }

    }

    const onSave = () => {
        // create whistleblower deanonymization request
        messageComponentReference.current.doSendMessage();
        hideDialog();
        if (messageType === DEANONYMISE){
            previewInfoDialog("Успешно подадено заявление за Деанонимизиране на подател",
                "Вашата заявка за деанонимизиране на подател е подадена успешно и очаква одобрение от страна на оператор.");
        } else {
            previewInfoDialog("Успешно подадено заявление за Деанонимизиране на извършител/и",
                "Вашата заявка за деанонимизиране на извършител/и е подадена успешно и очаква одобрение от страна на оператор.");
        }
    }

    const saveUIN = () => {
        occurrenceService().saveUIN(props.uuid, authService.getCurrentUser().user_name, uin)
            .then(() => {
                props.onUINChange();
                hideUINDialog();
            });
    }

    const deanonymiseDialogContent = () => {
        return (
            <OccurrenceMessageComponent async occurrenceId={props.occurrenceId} ref={messageComponentReference}
                                        userId={authService.getCurrentUser() !== null ? authService.getCurrentUser().user_name : ""}
                                        messageType={messageType} onSave={onSave} />
        );
    }
    const hideUINDialog = () => {
        setShowUINDialog(false);
    }

    const onUinChange = (e) => {
      setUin(e.target.value);
    }

    const hideConfirmationDialog = () => {
        setShowConfirmationDialog(false);
    }

    const confirmDeanonymization = () => {
        occurrenceService().respondToRequest(messageType == DEANONYMISE ? "deanonymization" : "perpetrator",
            props.uuid, CONFIRMED)
            .then(() => {
                hideConfirmationDialog();
                if (messageType == DEANONYMISE){
                    previewInfoDialog("ПОТВЪРЖДЕНИЕ за Деанонимизиране на подател",
                        "Успешно потвърдихте деанонимизиране на подател");
                } else {
                    previewInfoDialog("ПОТВЪРЖДЕНИЕ за Деанонимизиране на извършител/и",
                        "Успешно потвърдихте деанонимизиране на извършител/и.");
                }
            }
        );
    }

    const rejectDeanonymization = () => {
        occurrenceService().respondToRequest(messageType == DEANONYMISE ? "deanonymization" : "perpetrator",
            props.uuid, REJECTED)
            .then(() => {
                hideConfirmationDialog();
                if (messageType == DEANONYMISE) {
                    previewInfoDialog("ОТКАЗ за Деанонимизиране на подател",
                        "Успешно отказахте деанонимизиране на подател.");
                } else {
                    previewInfoDialog("ОТКАЗ за Деанонимизиране на извършител/и",
                        "Успешно отказахте деанонимизиране на извършител/и.");
                }
            }
        );
    }

    const footerConfirmationDialog = () => {
        return(
            <div>
                <Button label={t("cancel")} className="p-button-text" icon="pi pi-times" onClick={hideConfirmationDialog}
                        tooltip="Затваряте диалоговият прозорец" tooltipOptions={{ position: 'bottom' }}/>
                <Button label={t("no")} className="p-button-danger" icon="pi pi-times" onClick={rejectDeanonymization}
                        tooltip="Отказвате деанонимизиране" tooltipOptions={{ position: 'bottom' }}/>
                <Button label={t("yes")} icon="pi pi-check" onClick={confirmDeanonymization}
                        tooltip="Давате своето съгласие за деанонимизиране" tooltipOptions={{ position: 'bottom' }}/>
            </div>
        );
    }

    return(
        <div className="card">
            <div className="col-12 md:col-12 clearBoth">
                <div className="col-12 md:col-6 floatLeft">
                    <h2>{t('occurrence_details')}</h2>
                </div>
                <div className="col-12 md:col-6 floatRight">
                    <h4 className="floatRight border-bluegray-50">{t(props.source)}</h4>
                </div>
            </div>

            {uinContent()}

            <div className="col-12 md:col-12 clearBoth">
                <div className="col-12 md:col-4 floatLeft">
                    <label>{t("number")}</label>
                </div>
                <div className="col-12 md:col-8 floatRight">
                    <strong>{props.number}</strong>
                </div>
            </div>

            <div className="col-12 md:col-12 clearBoth">
                <div className="col-12 md:col-4 floatLeft">
                    <label>{t("subject")}</label>
                </div>
                <div className="col-12 md:col-8 floatRight">
                    <strong>{props.subject}</strong>
                </div>
            </div>

            <div className="col-12 md:col-12 clearBoth">
                <div className="col-12 md:col-4 floatLeft">
                    <label dangerouslySetInnerHTML={{__html: t('description')}} />
                </div>
                <div className="col-12 md:col-8 floatRight">
                    <strong>{props.description}</strong>
                </div>
            </div>

            <div className="col-12 md:col-12 clearBoth">
                <div className="col-12 md:col-4 floatLeft">
                    <label>{t("created_on")}</label>
                </div>
                <div className="col-12 md:col-8 floatRight">
                    <strong>{dateFormat(props.createdOn, DATETIME_FORMAT)}</strong>
                </div>
            </div>

            <div className="col-12 md:col-12 clearBoth">
                <div className="col-12 md:col-4 floatLeft">
                    <label>{t("last_update_on")}</label>
                </div>
                <div className="col-12 md:col-8 floatRight">
                    <strong>{dateFormat(props.lastUpdateOn, DATETIME_FORMAT)}</strong>
                </div>
            </div>

            <div className="col-12 md:col-12 clearBoth">
                <div className="col-12 md:col-4 floatLeft">
                    <label>{t("status")}</label>
                </div>
                <div className="col-12 md:col-8 floatRight">
                    <strong className="text-yellow-600">{t(props.status).toUpperCase()}</strong>
                </div>
            </div>

            <div className="col-12 md:col-12 clearBoth h-4rem">
                <div className="col-12 md:col-4 floatLeft">
                    <label>{t("category")}</label>
                </div>
                <div className="col-12 md:col-8 floatRight">
                    <strong>{props.categoryName}</strong>
                </div>
            </div>
            <strong>{props.showContact}</strong>

            {contactContent()}
            {perpetratorContent()}

            <FileListTable fileList={props.fileList}/>

            {/* Deanonymization Dialogs */}

            <Dialog visible={showDialog} onHide={hideDialog} className="wid70" footer={footerTemplate}
                    header={<h4>{ t("deanonymise") + " " + deanonymizeHeader}</h4>} focusOnShow={true}>
                {deanonymiseDialogContent()}
            </Dialog>

            {/* Informative Dialogs */}

            <Dialog visible={showInfoDialog} onHide={hideInfoDialog} className="wid70" footer={footerInfoTemplate}
                    header={<h4>{infoHeader}</h4>} focusOnShow={true}>
                {infoDialogContent()}
            </Dialog>

            <Dialog onHide={hideUINDialog} visible={showUINDialog} className="wid20" footer={footerUIN}
                    header={<h4>Редакция на УИН</h4>} focusOnShow={true}>
                <InputText value={uin} className="wid100" onChange={event => onUinChange(event)}
                           maxLength={12} onKeyPress={(event) => uinKeyPress(event)}/>
            </Dialog>

            <Dialog onHide={hideConfirmationDialog} visible={showConfirmationDialog} className="wid30" footer={footerConfirmationDialog}
                    header={<h4>Потвърждаване на деанонимизиране</h4>} focusOnShow={true}>
                <label>Потвърждавате ли деанонимизирането на подателя?</label>
            </Dialog>

        </div>
    );
}